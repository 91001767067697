import React from 'react';
import '../../styles/common/qr.scss';
import { QRProps } from '../../types/common/qrFace';

// Define the QR component
// This component displays a QR code in SVG format
const QR: React.FC<QRProps> = ({ svg, containerClassName, contentClassName }) => {
  return (
    <div className={containerClassName || 'qr-container'}>
      <div
        className={contentClassName || 'qr-content'}
        dangerouslySetInnerHTML={{ __html: svg }}
      />
    </div>
  );
};

export default QR;