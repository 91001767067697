import React from 'react';
import { FaKey, FaLayerGroup, FaUserCircle, FaWallet } from 'react-icons/fa';
import useUserProfile from '../hooks/useUserProfile';

// UserData component
// This component displays the user's profile data 
// It shows the user's email, wallet balance, liquid balance, registration level, and xpubkey
const UserData: React.FC = () => {
  const { userProfile, loading, error } = useUserProfile(true);

  const formatBalance = (balance: number) => {
    return (balance / 100000000).toFixed(8); // Convert satoshi to BTC and format to 8 decimal places
  };

  return (
    <div className='flex-1 flex flex-col space-y-10'>
      {loading && <p className='text-center text-gray-600'>Loading...</p>}
      {error && <p className='text-center text-red-600'>{error}</p>}
      {userProfile && (
        <div className='flex flex-col space-y-4'>
          <div className='flex items-center mb-8'>
            <FaUserCircle className='text-text text-3xl mr-2' />
            <h2 className='text-3xl'>Hi {userProfile.payload.email}!</h2>
          </div>
          <div className='flex items-center'>
            <FaWallet className='text-text mr-2' />
            <h2 className='text-lg'>Wallet balance: {formatBalance(userProfile.payload.balance_onchain)} BTC</h2>
          </div>
          <div className='flex items-center'>
            <FaWallet className='text-text mr-2' />
            <h2 className='text-lg'>Liquid balance: {formatBalance(userProfile.payload.balance_derived)} BTC</h2>
          </div>
          <div className='flex items-center'>
            <FaLayerGroup className='text-text mr-2' />
            <h2 className='text-lg'>Registration level: {userProfile.payload.auth_code}</h2>
          </div>
          <div className='flex items-center'>
            <FaKey className='text-text mr-2' />
            <h2 className='text-lg text-wrap'>Xpubkey: {userProfile.payload.xpub}</h2>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserData;