import { useState, useEffect } from 'react';
import { fetchPrice } from '../utils/price';

// Define the useFetchPrice hook
// This hook handles the logic for fetching the price of Bitcoin
// It uses the fetchPrice function from the utils to fetch the price
// It includes loading and error states
const useFetchPrice = () => {
  const [price, setPrice] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchPrice(setPrice, setLoading, setError); // Initial fetch
    const interval = setInterval(() => {
      fetchPrice(setPrice, setLoading, setError);
    }, 120000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return { price, loading, error };
};

export default useFetchPrice;