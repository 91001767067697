import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../config';

// This component displays the FAQ page
// It fetches the FAQ content from the server and displays it in an iframe
// It includes an error state to handle failed requests
// The component is exported to be used in other components

const FAQ: React.FC = () => {
  const [faqContent, setFaqContent] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchFaqContent = async () => {
      try {
        const response = await axios.get(`${API_URL}/_DLCP_FAQ.html`, {
          headers: {
            'Content-Type': 'text/html',
          },
        });
        setFaqContent(response.data);
      } catch (error) {
        setError('Failed to load FAQ content. Please try again later.');
        console.error('Error fetching FAQ content:', error);
      }
    };

    fetchFaqContent();
  }, []);

  return (
    <div className='faq-container flex flex-col space-y-4 my-4 px-3'>
      {error ? (
        <p className='text-red-500'>{error}</p>
      ) : (
        <iframe
          srcDoc={faqContent}
          title='FAQ'
          className='w-full h-screen border-none'
        />
      )}
    </div>
  );
};

export default FAQ;