import { useState, useEffect, useCallback } from 'react';
import { getUserProfile } from '../api/userProfile';
import { UserProfileResponse } from '../types/userFace';

// Define the useUserProfile hook
// This hook handles the logic for fetching the user's profile
// It uses the getUserProfile function from the API to send a GET request to the server
// It includes loading and error states
const useUserProfile = (isLoggedIn: boolean) => {
  const [userProfile, setUserProfile] = useState<UserProfileResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchUserProfile = useCallback(async () => {
    try {
      setLoading(true);
      const profile = await getUserProfile();
      setUserProfile(profile);
      setError(null); // Clear any previous errors
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unexpected error occurred.');
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      fetchUserProfile();
      const interval = setInterval(fetchUserProfile, 60000); // Fetch data every minute

      return () => clearInterval(interval); // Cleanup interval on component unmount
    }
  }, [isLoggedIn, fetchUserProfile]);

  return { userProfile, loading, error, refreshUserProfile: fetchUserProfile };
};

export default useUserProfile;