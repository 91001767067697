import React from 'react';
import { CheckboxProps } from '../../types/common/checkboxFace';

// Define the Checkbox component
// This component displays a checkbox with a label
const Checkbox: React.FC<CheckboxProps> = ({ label, checked, onChange }) => {
  return (
    <div className='flex items-top'>
      <input
        type='checkbox'
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        className='mr-2 mt-1 self-start'
      />
      <label className='text-sm text-text'>{label}</label>
    </div>
  );
};

export default Checkbox;
