import React from 'react';

// Define the FeaturesList component
// This component displays a list of features
// It also allows the user to view the features of the platform
const FeaturesList: React.FC = () => (
  <ul className='my-4 space-y-2 text-left text-text'>
    <li>• No trusted third party (not your keys, not your coins)</li>
    <li>
      • Universally accessible, peer-to-peer Bitcoin borrowing and lending
      marketplace
    </li>
    <li>
      • Lenders earn high fiat returns with secure smart contracts, eliminating
      settlement and credit risk
    </li>
    <li>
      • Borrowers Hodl with no margin call or liquidation if Bitcoin falls
    </li>
  </ul>
);

export default FeaturesList;
