import React, { useState } from 'react';
import UserSpecQR from '../components/UserSpecQr';
// import { useNavigate } from 'react-router-dom';
import Button from '../components/common/Button';
import useAuthLogic from '../hooks/useAuthLogic';

// Define the Register component
// This component handles the registration process
// It includes two steps: downloading the app and scanning the QR code
// It includes buttons for downloading the app and closing the registration
// It uses the useAuthLogic hook to handle user logout
// It returns the Register component
const Register: React.FC = () => {
  // const navigate = useNavigate();
  // const [xpub, setXpub] = useState('');
  const [step, setStep] = useState(1);
  const { handleLogout } = useAuthLogic();

  const handleCloseClick = () => {
    handleLogout();
    // navigate('/');
  };

  const handleDownloadClick = () => {
    // Megnyitjuk a linket egy új lapon, de nem ugrunk oda
    const newWindow = window.open(
      'https://github.com/sziller/_dlc_plaza_wallet',
      '_blank',
      'noopener,noreferrer'
    );
    if (newWindow) newWindow.opener = null;
    setStep(2);
  };

  return (
    <>
      <div className='fixed inset-0 bg-gray-800 bg-opacity-75 z-50 overflow-auto'>
        <div className='flex flex-col px-20 space-y-8 my-10 mx-auto py-10 bg-white w-2/3'>
          <h1 className='text-4xl text-text'>Last steps for registration</h1>
          <div>
            <p>
              In order to trade you need to download our signature protected
              app.
            </p>
            <p>
              This will allow you to sign a deal quickly and comfortably. Please
              follow the steps below.
            </p>
          </div>
          <div className='flex justify-stretch w-full items-center mx-auto'>
            <div
              className={`flex items-center justify-center w-8 h-8 rounded-full ${
                step >= 1 ? 'bg-black' : 'bg-secondary'
              }`}
            >
              <span className='text-white'>1</span>
            </div>
            <div
              className={`w-full border-t-2 ${
                step === 1 ? 'border-dashed border-secondary' : 'border-black'
              }`}
            ></div>
            <div
              className={`flex items-center justify-center w-8 h-8 rounded-full ${
                step === 2 ? 'bg-black' : 'bg-secondary'
              }`}
            >
              <span className='text-white'>2</span>
            </div>
          </div>
          <div className='flex justify-between w-full mx-auto'>
            <p className='text-center text-text'>Download and install</p>
            <p className='text-center text-text'>Scan the code</p>
          </div>
          {step === 1 ? (
            <>
              <div className='w-full mb-8'>
                <h3 className='text-2xl mb-3'>Step 1.</h3>
                <p>Download the Example Signatures app.</p>
              </div>
              <div className='flex justify-between'>
                <div>
                  <Button
                    label='Download and Install'
                    onClick={handleDownloadClick}
                    className='bg-button bg-button-hover px-10 rounded-sm'
                  />
                </div>
                <div>
                  <Button
                    label='Close'
                    onClick={handleCloseClick}
                    className='bg-button bg-button-hover px-10 rounded-sm'
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div>
                <h3 className='text-2xl mb-3'>Step 2.</h3>

                <p>Scan QR code with your APP and follow the instructions.</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col-1 lg:flex-col-2 gap-x-20'>
                  <div className=''>
                    <UserSpecQR />
                  </div>
                </div>
                <div className='flex items-end'>
                  <Button
                    label='Close'
                    onClick={handleCloseClick}
                    className='bg-button bg-button-hover px-10 rounded-sm'
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Register;
