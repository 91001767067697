import React from 'react';
import useFetchPrice from '../hooks/usePriceLogic'; // Import useFetchPrice

// Price component
// This component displays the current price of Bitcoin
const Price: React.FC = () => {
  const {price, loading, error} = useFetchPrice(); // Use useFetchPrice
  
  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    return <span>{error}</span>;
  }

  return <span>{price !== null ? price.toFixed(2) : 'N/A'}</span>;
};

export default Price;
