import React from 'react';
import OffersList from '../components/OffersList';
import UserDlcsList from '../components/UserDlcsList';
import OfferDetails from '../components/OfferDetails';
import useDashboardLogic from '../hooks/useDashboardLogic';
import FeatureButtons from '../components/FeatureButtons';
import useOfferListLogic from '../hooks/useOfferListLogic';
import ExplainerVideo from '../components/ExplainerVideo';
import FeaturesList from '../components/FeaturesList';
import Carousel from '../components/common/Carousel';
import useAuthLogic from '../hooks/useAuthLogic'; // Import useAuthLogic hook

// Define the Dashboard component
// This component displays the dashboard of the platform
// It also allows the user to view the offers, user DLCs, and offer details
const Dashboard: React.FC = () => {
  const { isLoggedIn } = useAuthLogic();
  const { selectedOffer, setSelectedOffer } = useDashboardLogic(isLoggedIn);
  const { refreshOffers } = useOfferListLogic();

  const carouselItems = [
    <div
      className='w-full h-full flex items-top justify-center bg-accent bg-cover bg-center relative'
      style={{ backgroundImage: "url('/images/crsl01.jpg')" }}
    >
      <h2 className='text-white text-2xl absolute bottom-10'>
        Cadena Bitcoin loans let peers access liquidity and generate fiat yield
        without a trusted third party.
      </h2>
    </div>,
    <div
      className='w-full h-full flex items-top justify-center bg-accent bg-cover bg-center relative'
      style={{ backgroundImage: "url('/images/crsl02.png')" }}
    >
      <h2 className='text-white text-2xl absolute bottom-10'>
        Deal at our live borrowing and lending rates or leave an order.
      </h2>
    </div>,
    <div
      className='w-full h-full flex items-top justify-center bg-accent bg-cover bg-center relative'
      style={{
        backgroundImage: "url('/images/crsl03.png')",
      }}
    >
      {/* <h2 className='text-white text-2xl absolute bottom-10'>
        We believe Bitcoin empowers people and that the "primary benefits are
        lost if a trusted third party is required".
      </h2> */}
    </div>,
    <div
      className='w-full h-full flex items-top justify-center bg-accent bg-cover bg-center relative'
      style={{ backgroundImage: "url('/images/crsl04.png')" }}
    >
      <h2 className='text-white text-2xl absolute bottom-10'>
        Cadena Bitcoin loans offer the security of on chain Bitcoin transaction
        using open source technology and a globally accessible platform.
      </h2>
    </div>,
  ];

  return (
    <div className=''>
      {isLoggedIn ? (
        <></>
      ) : (
        <>
          <div className='carousel-fix-height'>
            <Carousel items={carouselItems} />
          </div>
        </>
      )}
      <FeatureButtons />
      {isLoggedIn ? (
        <>
          <OffersList />
          <UserDlcsList />
        </>
      ) : (
        <>{/* <OffersList /> */}</>
      )}

      {/* <PriceList /> */}

      {isLoggedIn && selectedOffer && (
        <OfferDetails
          offer={selectedOffer}
          onClose={() => {
            setSelectedOffer(null);
            localStorage.removeItem('selectedOffer');
          }}
          refreshOffers={refreshOffers} // Pass the refreshOffers function
        />
      )}
      {isLoggedIn ? (
        <></>
      ) : (
        <>
          <div className='px-6'>
            <div className='flex flex-col md:flex-row md:space-x-8 w-full px-4 items-center justify-center'>
              <div className='w-full md:w-1/2 flex justify-center md:justify-end'>
                <ExplainerVideo />
              </div>
              <div className='w-full md:w-1/2'>
                <FeaturesList />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
