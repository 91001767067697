import React from 'react';
import { InputProps } from '../../types/common/inputFace';

// Define the Input component
// This component displays an input field with a label
const Input: React.FC<InputProps> = ({
  type,
  name,
  value,
  placeholder,
  onChange,
  className = 'w-full p-2 border border-gray-300 rounded text-black mb-2',
  required = false,
  autoComplete,
  readOnly,
}) => (
  <div className="mb-4">
    <label htmlFor={name} className="block text-text mb-1">
      {placeholder}
      {required && <span className="text-red-500 ml-1">*</span>}
    </label>
    <input
      type={type}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      className={className}
      required={required}
      autoComplete={autoComplete}
      readOnly={readOnly}
    />
  </div>
);

export default Input;