import { useState } from 'react';
import axios from 'axios'; // Import axios
import { acceptOffer } from '../api/acceptOffer';
import useAuthLogic from './useAuthLogic'; // Import useAuthLogic
import { AcceptOfferParams } from '../types/offerFace';

// This hook handles the logic for accepting an offer
// It returns a function to accept an offer with authentication
// It returns loading state and error message
export const useAcceptOffer = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { getToken } = useAuthLogic(); // Use getToken from useAuthLogic

  const acceptOfferWithAuth = async (params: AcceptOfferParams) => {
    setLoading(true);
    setError(null);
    try {
      const token = getToken();
      if (!token) {
        throw new Error('Unauthorized');
      }
      const response = await acceptOffer(params); // Pass only params
      return response;
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        setError(error.response.data.detail || error.message);
      } else if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unknown error occurred');
      }
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    acceptOfferWithAuth,
    loading,
    error,
  };
};