import axios from 'axios';
import { getAuthToken } from '../api/auth';

// This function sends a POST request to the server to log in a user
// It takes the email and password as arguments
// It also takes functions to set the user state, navigate to a different page,
// set loading state, set error state, and save the token
// It returns the token if successful, otherwise throws an error
export const login = async (
  email: string,
  password: string,
  setIsLoggedIn: (isLoggedIn: boolean) => void,
  setUserEmail: (email: string | null) => void,
  saveToken: (token: string) => void, // Add saveToken as a parameter
  navigate: (path: string) => void,
  setLoading: (loading: boolean) => void,
  setError: (error: string | null) => void
): Promise<string | null> => { // Return the token or null
  if (!email || !password) {
    alert('Email and password are required');
    return null;
  }

  setLoading(true);
  setError(null);
  try {
    const token = await getAuthToken(email, password);
    saveToken(token);
    localStorage.setItem('userEmail', email);
    setIsLoggedIn(true);
    setUserEmail(email);
    return token;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.code === 'ECONNABORTED') {
        alert('The request took too long - please try again later.');
      } else if (error.response) {
        console.error(
          'API request failed with status:',
          error.response.status
        );
        console.error(
          'Error details:',
          `${error.response.data.detail}` || 'No message available'
        );
        if (error.response.status === 400) {
          setError(`Bad Request: ${error.response.data.detail}`);
        } else if (error.response.status === 422) {
          const errorDetail = error.response.data.detail;
          if (Array.isArray(errorDetail)) {
            setError(errorDetail[0].msg);
          } else {
            setError('Validation error: Please check your email and password.');
          }
        } else {
          setError(`An error occurred: ${error.response.data.detail}`);
        }
      } else if (error.request) {
        console.error('No response received during API request.');
        setError('No response received. Please try again.');
      }
    } else {
      console.error(
        'Unexpected error:',
        error instanceof Error ? error.message : error
      );
      alert(`${error instanceof Error ? error.message : error}`);
      setError(`${error instanceof Error ? error.message : error}`);
    }
    return null;
  } finally {
    setLoading(false);
  }
};