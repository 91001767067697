import React from 'react';
import { DlcProps } from '../types/dlcFace';

// Define the Dlc component
// This component displays a list of DLCs
// It also allows the user to view details of a DLC by clicking a button
const Dlc: React.FC<DlcProps> = ({
  dlcs,
  textColor,
  statusLabel,
  statusStyle,
}) => {
  return (
    <>
      {dlcs.map((dlc, index) => (
        <div
          key={index}
          className='grid grid-cols-5 text-center gap-1 lg:gap-4 border py-1 px-4 shadow-sm transition-transform transform hover:scale-102 hover:shadow-md focus:scale-102 focus:shadow-md  bg-highlight-hover border-highlight'
        >
          <div className={`${textColor}`}>{dlc.dlc_id}</div>
          <div className={`${textColor}`}>{dlc.orcl_id}</div>
          <div className={`${textColor}`}>{dlc.interest}%</div>
          <div className={`${textColor}`}>
            <span className={`${statusStyle} rounded-sm`}>{statusLabel}</span>
          </div>
          <div>
            <button className='bg-button bg-button-hover rounded-sm text-sm py-0 px-2'>
              Details
            </button>
          </div>
        </div>
      ))}
    </>
  );
};

export default Dlc;
