import { useEffect } from 'react';

// Define the useIdleTimer hook
// This hook handles the user inactivity timer
// It takes a callback function to be executed on idle
// It also takes a timeout value in milliseconds
// It resets the timer on user activity
const useIdleTimer = (onIdle: () => void, timeout: number = 300000, isLoggedIn: boolean) => {
  useEffect(() => {
    if (!isLoggedIn) return;
    let timer: NodeJS.Timeout;

    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(onIdle, timeout);
    };

    const handleActivity = () => {
      resetTimer();
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    resetTimer();

    return () => {
      clearTimeout(timer);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, [onIdle, timeout, isLoggedIn]);
};

export default useIdleTimer;
