import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Button from '../components/common/Button';
import Input from '../components/common/Input';
import Checkbox from '../components/common/Checkbox';
import { LoginProps } from '../types/loginFace';
import { login } from '../utils/login';
import { confirmRegistration } from '../api/auth'; // Helyes import útvonal
import { AuthContext } from '../context/AuthContext';
import { getOfferById } from '../api/offer';
import { registerUser } from '../api/user';
import { getUserProfile } from '../api/userProfile'; // Import getUserProfile
import { UserFace } from '../types/userFace';

// Define the Login component
// This component handles the login and registration logic
const Login: React.FC<LoginProps> = ({ setIsLoggedIn, setUserEmail }) => {
  const { saveToken } = useContext(AuthContext);
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [registerEmail, setRegisterEmail] = useState('');
  const [registerPassword, setRegisterPassword] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const [showRegisterPassword, setShowRegisterPassword] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from || '/';

  // Use the useEffect hook to handle the registration confirmation
  // If the token is present in the URL, confirm the registration
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    if (token) {
      confirmRegistration(token)
        .then(({ email, password }: { email: string; password: string }) => {
          setLoginEmail(email);
          setLoginPassword(password);
        })
        .catch((error: Error) => {
          setError(error.message);
        });
    }
  }, [location.search]);

  // Define the handleLoginClick function
  // This function handles the login logic
  // It calls the login function from the utils folder
  // It sets the loading state to true while the request is being processed
  // It sets the error state if the login fails
  const handleLoginClick = async () => {
    setLoading(true);
    try {
      const token = await login(
        loginEmail,
        loginPassword,
        setIsLoggedIn,
        setUserEmail,
        saveToken,
        navigate,
        setLoading,
        setError
      );

      if (token) {
        const userProfile = await getUserProfile();
        const authCode = userProfile.payload.auth_code;

        if (authCode === 0) {
          alert('User is not registered.');
          setIsLoggedIn(false);
          setUserEmail(null);
          localStorage.removeItem('userEmail');
        } else if (authCode === 2) {
          // alert('Please confirm your email within 2 minutes.');
          navigate('/register');
        } else {
          const selectedOffer = localStorage.getItem('selectedOffer');
          const iniRole = localStorage.getItem('ini_role');
          if (selectedOffer) {
            const offerResponse = await getOfferById(
              JSON.parse(selectedOffer).dlc_id
            );
            localStorage.removeItem('selectedOffer');
            navigate(from, { state: { offer: offerResponse.payload } });
          } else if (iniRole) {
            navigate(from, { state: { ini_role: iniRole } });
          } else {
            navigate(from);
          }
        }
      }
    } catch (error) {
      setError('Login failed. Please check your credentials and try again.');
    } finally {
      setLoading(false);
    }
  };

  // Define the handleRegisterClick function
  // This function handles the registration logic
  // It calls the registerUser function from the API
  // It sets the loading state to true while the request is being processed
  // It sets the error state if the registration fails
  const handleRegisterClick = async () => {
    const newUser: UserFace = {
      email_address: registerEmail,
      password: registerPassword,
    };

    try {
      setLoading(true);
      await registerUser(newUser);
      setLoading(false);
      setRegistrationSuccess(true);
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unexpected error occurred.');
      }
    }
  };

  // This function handles the cancel logic
  const handleCancelClick = () => {
    navigate('/');
  };

  // This function handles the sign in logic
  const handleSignInClick = () => {
    setRegistrationSuccess(false);
    setRegisterEmail('');
    setRegisterPassword('');
    setAgreeToTerms(false);
  };

  return (
    <div className='fixed inset-0 bg-gray-800 bg-opacity-75 z-50 overflow-auto'>
      <div className='flex flex-col lg:flex-row space-y-8 md:space-y-0 md:space-x-10 m-20 p-10 pb-20 bg-highlight'>
        {registrationSuccess ? (
          <div className='flex-1 flex flex-col items-center justify-center'>
            <h1 className='text-4xl text-center text-text'>Thank you!</h1>
            <p className='text-md text-center text-text mx-auto'>
              Please check your email address and confirm it within 2 minutes.
              Come back to sign in.
            </p>
            <Button
              label='Sign in'
              onClick={handleSignInClick}
              className='bg-button bg-button-hover px-10 mt-4'
            />
          </div>
        ) : (
          <>
            <div className='flex-1 flex flex-col space-y-8'>
              <h1 className='text-4xl text-center mt-8 text-text'>
                Welcome back
              </h1>
              <p className='text-md w-2/3 text-center text-text mx-auto'>
                If you want to browse the offers in detail, make a deal or make
                a bid, register here.
              </p>
              <form
                onSubmit={(e) => e.preventDefault()}
                className='flex flex-col space-y-2 w-full md:w-3/4 lg:w-2/3 xl:w-1/2 mx-auto'
              >
                <Input
                  type='email'
                  name='loginEmail'
                  value={loginEmail}
                  placeholder='Email address'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setLoginEmail(e.target.value)
                  }
                  required
                  autoComplete='email'
                />
                <div className='relative'>
                  <Input
                    type={showLoginPassword ? 'text' : 'password'}
                    name='loginPassword'
                    value={loginPassword}
                    placeholder='Password'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setLoginPassword(e.target.value)
                    }
                    required
                    autoComplete='password'
                  />
                  <button
                    type='button'
                    className='absolute inset-y-0 right-0 pr-3 flex items-center text-lg leading-5'
                    onClick={() => setShowLoginPassword(!showLoginPassword)}
                  >
                    {showLoginPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                <div className='flex flex-row justify-between space-x-4'>
                  <div className='w-full'>
                    <Button
                      label='Sign in'
                      onClick={handleLoginClick}
                      disabled={loading}
                      className='bg-button bg-button-hover px-10'
                    />
                  </div>
                  <div className='w-full'>
                    <Button
                      label='Cancel'
                      onClick={handleCancelClick}
                      className='bg-button bg-button-hover px-10'
                    />
                  </div>
                </div>
                {loading && (
                  <p className='text-center text-gray-600'>
                    Processing request...
                  </p>
                )}
                {error && <p className='text-center text-red-600'>{error}</p>}
              </form>
            </div>
            <div className='flex-1 flex flex-col space-y-8'>
              <h1 className='text-4xl text-center mt-8 text-text'>
                Create Account
              </h1>
              <p className='text-md w-2/3 text-center text-text mx-auto'>
                If you want to browse the offers in detail, make a deal or make
                a bid, register here.
              </p>
              <form
                onSubmit={(e) => e.preventDefault()}
                className='flex flex-col space-y-2 w-full md:w-3/4 lg:w-2/3 xl:w-1/2 mx-auto'
              >
                <Input
                  type='email'
                  name='registerEmail'
                  value={registerEmail}
                  placeholder='Email address'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setRegisterEmail(e.target.value)
                  }
                  required
                  autoComplete='off'
                />
                <div className='relative'>
                  <Input
                    type={showRegisterPassword ? 'text' : 'password'}
                    name='registerPassword'
                    value={registerPassword}
                    placeholder='Password'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setRegisterPassword(e.target.value)
                    }
                    required
                    autoComplete='new-password'
                  />
                  <button
                    type='button'
                    className='absolute inset-y-0 right-0 pr-3 flex items-center text-lg leading-5'
                    onClick={() =>
                      setShowRegisterPassword(!showRegisterPassword)
                    }
                  >
                    {showRegisterPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                <div>
                  <Checkbox
                    label='By clicking Register, you agree to our Terms and you have read our Data Policy including our Cookie Use.'
                    checked={agreeToTerms}
                    onChange={setAgreeToTerms}
                  />
                </div>
                <div className='flex flex-row justify-between space-x-4'>
                  <div className='w-full'>
                    <Button
                      label='Register'
                      onClick={handleRegisterClick}
                      disabled={
                        loading ||
                        !agreeToTerms ||
                        !registerEmail ||
                        !registerPassword
                      }
                      className='bg-button bg-button-hover px-10'
                    />
                  </div>
                  <div className='w-full'>
                    <Button
                      label='Cancel'
                      onClick={handleCancelClick}
                      className='bg-button bg-button-hover px-10'
                    />
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
