import React from 'react';
import UserData from '../components/UserData';
import UserTopupAddressQR from '../components/UserTopupAddressQr';

// The MyProfile component is a functional component that renders the user profile page
// The component renders the user data and a QR code for top-up address
const MyProfile: React.FC = () => {
  const SeqNr = -1;
  return (
    <>
      <div className='flex flex-col px-8 md:px-20 space-y-10 my-10 md:mx-20 pt-10 pb-20 bg-highlight'>
        {/* <h1 className='text-4xl text-center text-text'>User Profile</h1> */}
        <UserData />
        <div className='flex flex-col-1 lg:flex-col-2 gap-x-20'>
          <div className='w-1/2'>
            <h3 className='text-2xl mb-5'>Top up QR code</h3>

            <UserTopupAddressQR seqNr={SeqNr} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
