import React, { useState, useEffect } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

// Define the CarouselProps interface
interface CarouselProps {
  items: React.ReactNode[];
}

// Define the Carousel component
// This component displays a carousel of items
// It allows the user to navigate between the items 
// and displays the current item index
const Carousel: React.FC<CarouselProps> = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === items.length - 1 ? 0 : prevIndex + 1
      );
    }, 15000);

    return () => clearInterval(interval);
  }, [items.length]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className='carousel w-full h-full relative'>
      <button
        onClick={handlePrev}
        className='absolute left-4 top-1/2 transform -translate-y-1/2 text-white z-10'
      >
        <FaAngleLeft />
      </button>
      <div className='carousel-item w-full h-full'>{items[currentIndex]}</div>
      <button
        onClick={handleNext}
        className='absolute right-4 top-1/2 transform -translate-y-1/2 text-white'
      >
        <FaAngleRight />
      </button>
      <div className='carousel-indicators absolute bottom-0 left-1/2 transform -translate-x-1/2 flex space-x-2'>
        {items.map((_, index) => (
          <div
            key={index}
            className={`w-10 h-1 mb-2 rounded-sm ${
              index === currentIndex ? 'bg-black' : 'bg-background'
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
