import React from 'react';
import { ButtonProps } from '../../types/common/buttonFace'; // Import the ButtonProps interface

// Define the Button component
// This component displays a button with an optional icon
const Button: React.FC<ButtonProps> = ({
  label,
  icon,
  onClick,
  disabled,
  loading = false,
  className, // Add className prop
}) => (
  <button
    className={`p-2 shadow-md font-normal text-text transition duration-300 ease-in-out transform ${
      loading
        ? 'bg-gray-300 cursor-not-allowed text-gray-300'
        : ' focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50'
    } ${disabled ? 'opacity-50 cursor-not-allowed' : ''} ${className}`} // Apply className prop
    onClick={onClick}
    disabled={disabled || loading}
    aria-busy={loading ? 'true' : 'false'}
  >
    {loading ? (
      <svg
        className='animate-spin h-5 w-5 mr-3 text-gray-700 inline-block'
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
      >
        <circle
          className='opacity-25'
          cx='12'
          cy='12'
          r='10'
          stroke='currentColor'
          strokeWidth='4'
        ></circle>
        <path
          className='opacity-75'
          fill='currentColor'
          d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
        ></path>
      </svg>
    ) : (
      icon || label
    )}
  </button>
);

export default Button;
