import React from 'react';
import { useUserQR } from '../hooks/useUserQR';
import QR from './common/QR';
import LoadingError from './common/LoadingError';

// UserSpecQR component
// This component displays a QR code for a user
const UserSpecQR: React.FC = () => {
  const { qrCode, loading, error } = useUserQR();

  return (
    <>
      <LoadingError loading={loading} error={error} />
      {qrCode && <QR svg={qrCode.payload.qr_code} />}
    </>
  );
};

export default UserSpecQR;