import React from 'react';
import Button from './common/Button'; // Import the Button component
import { ButtonProps } from '../types/common/buttonFace'; // Import the ButtonProps interface
import usePostAuthLogic from '../hooks/usePostAuthLogic';
import OfferDetails from './OfferDetails';
import useOfferListLogic from '../hooks/useOfferListLogic';
import useOfferLogic from '../hooks/useOfferLogic';
import { FaHandHoldingUsd } from 'react-icons/fa';
import '../styles/best_deal.scss';

// Define the AskButton component
// This component displays the best deal for borrowing Bitcoin
// It also allows the user to leave an order to borrow Bitcoin at their rate
const AskButton: React.FC<ButtonProps> = ({ onClick }) => {
  const { isLoggedIn, handleLoginRedirect } = usePostAuthLogic();
  const { lendOffers, loading, error, refreshOffers } = useOfferListLogic();
  const { handleOfferClick, selectedOffer, handleCloseDetails } =
    useOfferLogic();

  const handleClick = () => {
    if (!isLoggedIn) {
      handleLoginRedirect('borrow');
    } else {
      localStorage.setItem('ini_role', 'borrow');
      onClick?.();
    }
  };

  return (
    <div className='flex flex-col w-2/3 text-center justify-start'>
      {lendOffers.length > 0 ? (
        <div className='relative flex flex-col justify-center items-between bg-highlight rounded-xl'>
          {/* <div className='best-deal'>Best deal</div> */}
          {/* <div className='flex justify-between items-stretch ps-10'> */}
          <div className='flex justify-center'>
            {/* <FaHandHoldingUsd className='text-6xl mt-8' /> */}
            <h1 className='w-full text-2xl mt-8 mb-4'>Borrow</h1>
            {/* <div className='bg-black text-white rounded-tr-xl'>Best deal</div> */}
          </div>
          <div className='flex justify-center'>
            <h1 className='w-full text-4xl mb-8'>{`${lendOffers[0].interest}%`}</h1>
          </div>
          <div className='flex'>
            <Button
              label={`Details`}
              onClick={() => handleOfferClick(lendOffers[0])}
              className='rounded-xl rounded-t-none bg-button bg-button-hover w-full py-2 mt-auto font-normal'
            />
          </div>
        </div>
      ) : (
        <div className='relative flex flex-col justify-center items-between bg-highlight text-text rounded-xl '>
          <div className='flex justify-center'>
            <h1 className='w-full text-2xl mt-8 mb-4'>Borrow</h1>
          </div>
          <div className='flex justify-center'>
            <h1 className='w-full text-4xl mb-8'>:(</h1>
          </div>
          <div className='flex rounded-xl rounded-t-none w-full py-5 mt-auto'></div>
        </div>
      )}
      {isLoggedIn && (
        <div className='flex flex-col justify-center items-between bg-accent text-text rounded-xl mt-6'>
          <div className='flex justify-between items-center ps-10'>
            <FaHandHoldingUsd className='text-6xl mt-8' />
            <h1 className='w-full text-2xl mt-10 pe-20'>Ask</h1>
          </div>
          <div className='flex mx-auto my-5'>
            <p className='text-center'>
              Leave an order to borrow Bitcoin at your rate.
            </p>
          </div>
          <div className='flex'>
            <Button
              label='Details'
              onClick={handleClick}
              className='rounded-xl rounded-t-none bg-button bg-button-hover  w-full py-2 mt-auto font-normal'
            />
          </div>
        </div>
      )}

      {loading && <p>Loading offers...</p>}
      {error && <p>{error}</p>}
      {selectedOffer && (
        <OfferDetails
          offer={selectedOffer}
          onClose={handleCloseDetails}
          refreshOffers={refreshOffers}
        />
      )}
    </div>
  );
};

export default AskButton;
