import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './components/Header';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import FAQ from './pages/FAQ'; // Import the FAQ component
import Contact from './pages/Contact'; // Import the Contact component
import { AuthProvider } from './context/AuthContext'; // Import AuthProvider
import useAuthLogic from './hooks/useAuthLogic'; // Import useAuthLogic hook
import MyProfile from './pages/MyProfile';
import Register from './pages/Register';
import Footer from './components/Footer';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
};

const AppContent: React.FC = () => {
  const { isLoggedIn, setIsLoggedIn, userEmail, setUserEmail, saveToken } =
    useAuthLogic();

  return (
    <div className='min-h-screen grid grid-rows gap-8 bg-background text-text'>
      <div className='flex flex-col'>
        <Header
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          userEmail={userEmail}
        />
        <Routes>
          <Route
            path='/loginpage'
            element={
              <Login
                setIsLoggedIn={setIsLoggedIn}
                setUserEmail={setUserEmail}
                saveToken={saveToken}
              />
            }
          />
          <Route
            path='/'
            element={<Dashboard  />}
          />
          <Route
            path='/myprofile'
            element={<MyProfile />}
          />
          <Route
            path='/register'
            element={<Register />}
          />
          <Route
            path='/faq'
            element={<FAQ />} // Add the FAQ route
          />
          <Route
            path='/contact'
            element={<Contact />} // Add the Contact route
          />
          {/* Add a catch-all route for 404 */}
          <Route
            path='*'
            element={<div>404 Not Found</div>}
          />
        </Routes>
        <Footer />
      </div>
    </div>
  );
};

export default App;
