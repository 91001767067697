import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FaFacebookF, FaYoutube } from 'react-icons/fa';

// Define the Footer component
// This component displays the footer of the platform with links to contact, FAQ, and download
const Footer: React.FC = () => (
  <footer className='bg-primary mt-auto w-full'>
    <div className='flex justify-end text-sm space-x-4 text-text py-2 px-20'>
      <RouterLink
        to='/contact'
        className='text-text text-sm'
      >
        Contact
      </RouterLink>
      <RouterLink
        to='/faq'
        className='text-text text-sm'
      >
        FAQ
      </RouterLink>
      <a
        href='https://github.com/sziller/_dlc_plaza_wallet'
        target='_blank'
        rel='noopener noreferrer'
      >
        Download
      </a>
    </div>
    <div className='flex justify-between border-t border-accent py-2 px-20'>
      <div className='text-accent'>Copyright 2025. All rights reserved.</div>
      {/* Placeholder for social media icons */}
      <div className='flex flex-row space-x-4'>
        <div className='bg-accent text-text w-8 h-8 rounded-full flex items-center justify-center'>
          <a
            href='https://x.com/cadenabitcoin?s=21&t=UAfnWHGfFtaefkcCiNe2Kw'
            target='_blank'
            rel='noopener noreferrer'
            className='font-bold'
          >
            X
          </a>
        </div>
        <div className='bg-accent text-text w-8 h-8 rounded-full flex items-center justify-center'>
          <a
            href='https://www.youtube.com/@CadenaBitcoin'
            target='_blank'
            rel='noopener noreferrer'
          >
            <FaYoutube />
          </a>
        </div>
        <div className='bg-accent text-text w-8 h-8 rounded-full flex items-center justify-center'>
          <a
            href='https://www.facebook.com/profile.php?id=61568348506268&mibextid=wwXIfr&rdid=hQrs4oyWVJS7fVLc&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2F15YYz6cP1W%2F%3Fmibextid%3DwwXIfr#'
            target='_blank'
            rel='noopener noreferrer'
          >
            <FaFacebookF />
          </a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
