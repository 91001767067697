import React from 'react';
import { FaBitcoin, FaEnvelope, FaPhone } from 'react-icons/fa';

// Define the Contact component
// This component displays the contact information for the website
const Contact: React.FC = () => {
  const textShadowStyle = {
    textShadow:
      '1px 1px 2px #000, -1px -1px 2px #000, 1px -1px 2px #000, -1px 1px 2px #000',
  };

  return (
    <div className='h-2/3  relative'>
      <div
        className='flex flex-col lg:flex-row my-10

         mx-20 p-10 pb-20 bg-highlight bg-cover bg-center relative h-full 
        '
        style={{ backgroundImage: "url('/images/contactbg.jpg')" }}
      >
        <div className='flex-1 flex flex-col  text-white'>
          <div className='w-4/5 md:w-2/3 lg:w-1/2 mx-auto md:px-10 py-20 space-y-10 '>
            <h1
              className='text-4xl text-start mt-8 text-white'
              style={textShadowStyle}
            >
              Contact Us
            </h1>
            <p
              className='text-md text-start text-white'
              style={textShadowStyle}
            >
              If you have any questions, please contact us at the following
              details.
            </p>
            <div className='flex flex-col space-y-4'>
              <div
                className='flex items-center'
                style={textShadowStyle}
              >
                <p>Kevin Bell</p>
              </div>
              <div
                className='flex items-center'
                style={textShadowStyle}
              >
                <FaBitcoin className='text-black mr-2' />
                <p>Cadena Bitcoin</p>
              </div>
              <div
                className='flex items-center'
                style={textShadowStyle}
              >
                <FaEnvelope className='text-black mr-2' />
                <p>kevin@cadenabitcoin.com</p>
              </div>
              <div
                className='flex items-center'
                style={textShadowStyle}
              >
                <FaEnvelope className='text-black mr-2' />
                <p>info@cadenabitcoin.com</p>
              </div>
              <div
                className='flex items-center'
                style={textShadowStyle}
              >
                <FaPhone className='text-black mr-2' />
                <p>416-769-1440</p>
              </div>
              <div
                className='flex items-center'
                style={textShadowStyle}
              >
                <FaPhone className='text-black mr-2' />
                <p>506-8623-5890</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
