import { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext'; // Import AuthContext
import useIdleTimer from './useIdleTimer'; // Import useIdleTimer hook
import { logout } from '../api/auth'; // Import logout function

// Define the useAuthLogic hook
// This hook handles the user authentication logic
// It checks if the user is logged in and sets the logged in state
// It also handles user logout
// It uses the useIdleTimer hook to handle inactivity
// It returns the necessary state and functions to be used in other components
const useAuthLogic = () => {
  const { saveToken, getToken } = useContext(AuthContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = getToken();
    const email = localStorage.getItem('userEmail');
    if (token) {
      setIsLoggedIn(true);
      if (email) {
        setUserEmail(email);
      }
    }
  }, [getToken]);

  const handleLogout = useCallback(() => {
    logout();
    setIsLoggedIn(false);
    localStorage.removeItem('userEmail');
    navigate('/loginpage');
    window.location.reload();
  }, [navigate]);

  // Use the useIdleTimer hook to handle inactivity
  useIdleTimer(handleLogout, 300000, isLoggedIn); // 5 min

  return {
    isLoggedIn,
    setIsLoggedIn,
    userEmail,
    setUserEmail,
    saveToken,
    getToken,
    handleLogout,
  };
};

export default useAuthLogic;