import { useState, useEffect } from 'react';
import { getTopupAddressQR } from '../api/qr';
import { QRCodeAddressResponse } from '../types/common/qrFace';

// Define the useTopupAddressQR hook
// This hook handles the logic for fetching the topup address QR code
// It uses the getTopupAddressQR function from the API to send a GET request to the server
// It includes loading and error states
export const useTopupAddressQR = (SeqNr: number) => {
  const [qrCode, setQRCode] = useState<QRCodeAddressResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchQRCode = async () => {
      try {
        const response = await getTopupAddressQR(SeqNr);
        setQRCode(response);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('An unexpected error occurred.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchQRCode();
  }, [SeqNr]);

  return { qrCode, loading, error };
};