import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import useIdleTimer from './useIdleTimer';
import { logout } from '../api/auth';
import { OfferData } from '../types/offerFace';

// Define the useDashboardLogic hook
// This hook handles the logic for the dashboard page
// It manages the selected offer state and handles user inactivity
// It returns the selected offer and a function to set the selected offer
const useDashboardLogic = (isLoggedIn: boolean) => {
  const [selectedOffer, setSelectedOffer] = useState<OfferData | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { setIsLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    if (isLoggedIn) {
      const savedOffer = localStorage.getItem('selectedOffer');
      if (savedOffer) {
        setSelectedOffer(JSON.parse(savedOffer));
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && location.state?.offer) {
      setSelectedOffer(location.state.offer);
      localStorage.setItem('selectedOffer', JSON.stringify(location.state.offer));
    }
  }, [isLoggedIn, location.state]);

  useEffect(() => {
    const handlePopState = () => {
      logout();
      setIsLoggedIn(false);
      setSelectedOffer(null);
      localStorage.removeItem('selectedOffer');
      navigate('/loginpage');
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate, setIsLoggedIn]);

  useIdleTimer(() => {
    logout();
    setIsLoggedIn(false);
    setSelectedOffer(null);
    localStorage.removeItem('selectedOffer');
    navigate('/loginpage');
  }, 300000, isLoggedIn);

  return {
    selectedOffer,
    setSelectedOffer,
  };
};

export default useDashboardLogic;