import React, { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { removeToken } from '../api/token';
import { FaBars, FaUserCircle, FaWallet } from 'react-icons/fa';
import Price from '../components/Price';
import useUserProfile from '../hooks/useUserProfile';

// Define the Header component
// This component displays the header of the website and allows the user to navigate to different pages
// It also allows the user to logout
const Header: React.FC<{
  isLoggedIn: boolean;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  userEmail: string | null;
}> = ({ isLoggedIn, setIsLoggedIn, userEmail }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { userProfile, loading, error } = useUserProfile(isLoggedIn);

  const handleLogout = () => {
    removeToken();
    localStorage.removeItem('token');
    localStorage.removeItem('ini_role');
    localStorage.removeItem('userEmail');
    setIsLoggedIn(false);
    navigate('/');
    window.location.reload();
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const formatBalance = (balance: number) => {
    return (balance / 100000000).toFixed(8); // Convert satoshi to BTC and format to 8 decimal places
  };

  return (
    <header className=''>
      <div className='bg-background shadow-md mx-auto py-4 px-20 flex justify-between items-start'>
        <div className='text-text'>Cadena Bitcoin</div>
        <nav
          className={`${
            isOpen ? 'flex px-5' : 'hidden'
          } flex-col lg:flex lg:flex-row lg:items-center lg:space-x-10`}
        >
          <RouterLink to='/' className='text-text text-sm py-1'>
            Dashboard
          </RouterLink>
          <RouterLink to='/contact' className='text-text text-sm py-1'>
            Contact
          </RouterLink>
          <RouterLink to='/faq' className='text-text text-sm py-1'>
            FAQ
          </RouterLink>
          <a
            href='https://github.com/sziller/_dlc_plaza_wallet'
            target='_blank'
            rel='noopener noreferrer'
            className='text-text text-sm py-1'
          >
            Download
          </a>
          {isLoggedIn ? (
            <>
              <RouterLink
                to='/myprofile'
                className='bg-button text-text text-sm py-1 px-5 rounded-sm'
              >
                MyProfile
              </RouterLink>
              <RouterLink
                to='/'
                onClick={handleLogout}
                className='bg-transparent hover:bg-transparent text-text text-sm py-1 rounded-sm'
              >
                Logout
              </RouterLink>
            </>
          ) : (
            <RouterLink
              to='/loginpage'
              className='bg-button text-text text-sm py-1 px-5 rounded-sm'
            >
              Sign in
            </RouterLink>
          )}
        </nav>
        <div className='flex items-center lg:hidden'>
          <button
            className='lg:hidden text-text focus:outline-none ml-auto'
            onClick={toggleMenu}
          >
            <FaBars className='w-8 h-8' /> {/* Larger icon size */}
          </button>
        </div>
      </div>
      <div className='bg-primary shadow-md mx-auto pt-2 pb-1 px-20 flex justify-between items-center'>
        <div className='text-text text-md justify-self-start'>
          BTC/USD
          <strong className='text-text mx-3'>
            <Price />
          </strong>
        </div>
        {isLoggedIn && (
          <div className='flex items-end'>
            {userEmail && (
              <>
                <FaUserCircle className='text-highlight text-xl mx-2' />
                <p className='text-sm text-text me-20'>{userEmail}</p>
                <FaWallet className='text-highlight text-xl mx-2' />
                <p className='text-sm text-text'>
                  {loading ? 'Loading...' : error ? `Error: ${error}` : `${formatBalance(userProfile?.payload.balance_derived || 0)} BTC`}
                </p>
              </>
            )}
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;