import { getPrice } from '../api/price';

// This function fetches the price from the server
// It takes three arguments: setPrice, setLoading, and setError
export const fetchPrice = async (
  setPrice: (price: number | null) => void,
  setLoading: (loading: boolean) => void,
  setError: (error: string | null) => void
) => {
  setLoading(true);
  setError(null);
  try {
    const price = await getPrice();
    setPrice(price);
    setError(null);
  } catch (error) {
    setError('Failed to fetch price');
  } finally {
    setLoading(false);
  }
};