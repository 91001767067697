import axios, { AxiosError } from 'axios';
import { DlcData } from '../types/dlcFace';
import { getUserDlcs } from '../api/userDlcs';

interface ErrorResponse {
  message?: string;
}

// This function fetches the user's DLCs from the server
// It sets the loading state to true while fetching the data
// If successful, it sets the DLCs in the state
// If an error occurs, it sets the error message in the state
// It logs the error to the console
export const fetchDlcs = async (
  setDlcs: (dlcs: DlcData[]) => void,
  setLoading: (loading: boolean) => void,
  setError: (error: string | null) => void
) => {
  setLoading(true);
  setError(null);
  try {
    const response = await getUserDlcs();
    setDlcs(response.payload);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ErrorResponse>;
      console.error('Failed to fetch DLCs:', axiosError.response?.data || axiosError.message);
      setError(`Failed to fetch DLCs: ${axiosError.response?.data?.message || axiosError.message}`);
    } else {
      console.error('Unexpected error:', error);
      setError('Unexpected error occurred');
    }
  } finally {
    setLoading(false);
  }
};