import React from 'react';
import { useTopupAddressQR } from '../hooks/useTopupAddress';
import QR from './common/QR';
import Button from './common/Button';
import LoadingError from './common/LoadingError';


interface UserTopupAddressQRProps {
  seqNr: number;
}

// Define the UserTopupAddressQR component
// This component displays a QR code for a user's topup address
const UserTopupAddressQR: React.FC<UserTopupAddressQRProps> = ({ seqNr }) => {
  const { qrCode, loading, error } = useTopupAddressQR(seqNr);

  const handleCopyClick = () => {
    if (qrCode) {
      navigator.clipboard.writeText(qrCode.payload.string).then(() => {
        alert('Address copied to clipboard');
      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
    }
  };

  return qrCode ? (
    <div>
      <QR
        svg={qrCode.payload.qr_code}
        containerClassName='user-topup-qr-container'
        contentClassName='user-topup-qr-content'
      />
      <Button
        label='Copy'
        onClick={handleCopyClick}
        className='bg-button bg-button-hover rounded-sm text-sm py-0 px-2'
      />
      <LoadingError loading={loading} error={error} />
    </div>
  ) : null;
};

export default UserTopupAddressQR;